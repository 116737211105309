import React from 'react'

function Home() {
    return (
        <div className="home container">
            <h1 class="display-3 mb-5 text-center">Welcome to Armaan-Verse!</h1>
        </div>
    )
}

export default Home;