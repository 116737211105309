import React from "react"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import Home from './components/pages/Home'
import About from './components/pages/About'
import Explore from './components/pages/Explore'
import Adventures from './components/pages/Adventures'
import Books from './components/pages/Books'
import Blog from './components/pages/Blog'
import Store from './components/pages/Store'
import Navbar from './components/layout/Navbar'

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/home' component={Home} />
            <Route path='/about' component={About} />
            <Route path='/explore' component={Explore} />
            <Route path='/adventures' component={Adventures} />
            <Route path='/books' component={Books} />
            <Route path='/blog' component={Blog} />
            <Route path='/store' component={Store} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
