import React, { useState, useEffect } from 'react'
import { xml2js } from 'xml-js'
import Rating from '@material-ui/lab/Rating'
import Loading from '../util/Loading'

function Books() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState([]); 
  
    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()

    useEffect(() => {
      fetch("https://us-central1-armaan-verse.cloudfunctions.net/getDataFromGoodReads")
        .then(res => res.text())
        .then(
          (responseText) => {
            console.log(responseText);
            setIsLoaded(true);
            var options = {compact: true, ignoreComment: true, alwaysChildren: true};
            var result = xml2js(responseText, options); // or convert.xml2json(xml, options)
            console.log(result);
            setTotal(result.GoodreadsResponse.reviews._attributes.total);
            setItems(result.GoodreadsResponse.reviews.review);
            //setItems(result.GoodreadsResponse.reviews);
            //xml2js(responseText, function (err, result) {
              //  console.log(result);
                //setItems(result);
            //});
            
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }, [])
  
    if (error) {
      return <div className="books container">Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <Loading />
    } else {
      return (
        <div className="books container">
          <h1 class="display-3 mb-5">My Bookshelf</h1>
          <div className="">
            <h2>Authored</h2>
            <p className="mt-5 mb-5">Coming soon...</p>
          </div>
          <div className="mb-5">
            <h2>Read ({total})</h2>
          </div>
          <div className="card-columns">
            {items.map(review => (
              <a href={review.link._cdata} target="_blank">
                <div key={review.id._text} className="card mb-3">
                  <div className="row no-gutters">
                    <div className="col-md-4">
                      <img src={review.book.image_url._text} className="card-img" alt="{review.book.title._text}" />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5 className="card-text">{review.book.title._text}</h5>
                        <Rating name="read-only" value={review.book.average_rating._text} readOnly />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
      );
    }
}

export default Books;