import React from 'react'

function Explore() {
    return (
        <div className="explore container">
            &nbsp;
        </div>
    )
}

export default Explore;