import React from 'react'
import { Link } from "react-router-dom"

function Navbar() {
    return (
        <nav className="nav sticky-top justify-content-center">
            <span><Link to="/">Home</Link></span>
            <span><Link to="/about">About</Link></span>
            <span><Link to="/explore">Explore</Link></span>
            <span><Link to="/books">Books</Link></span>
            <span><Link to="/adventures">Adventures</Link></span>
            <span><Link to="/blog">Blog</Link></span>
            <span><Link to="/store">Store</Link></span>
        </nav>
    )
}

export default Navbar;