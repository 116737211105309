import React from 'react'

function Adventures() {
    return (
        <div className="adventures container">
            <h1 class="display-3 mb-5">My Adventures</h1>
            Here is the log of my key adventures.
        </div>
    )
}

export default Adventures;