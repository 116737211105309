import React from 'react'

function Blog() {
    return (
        <div className="container">
            <h1 class="display-3 mb-5">My Blog</h1>
            This is my blog page.
        </div>
    )
}

export default Blog;