import React from 'react'

function Loading() {
    return (
        <div className="container text-center">
            <div class="spinner-border text-light" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
  )
}

export default Loading;