import React from 'react'

function About() {
    return (
        <div className="about container">
            <div className="row mb-5">
                <div className="col text-center align-middle">
                    <img src="..." className="rounded mx-auto d-block" alt="My Photos" />
                </div>
                <div className="col">
                    <h1 class="display-3 mb-5">About Me</h1>
                    <p>Hi, I am Armaan Alei. Welcome to my universe. <br/>I am 6 years old and a Grade 2 student. I was born in India but now we live in New Jersey, USA.</p>
                    <br/>
                    <h3>What I like?</h3>
                    <p>I like to go on adventures and learn new things.</p>
                    <br/>
                    <h3>What I am learning in 2020?</h3>
                    <p>This year, I am learning about sea creatures and facts about them. I am also curious about Earth's history and Prehistoric life. I think it's fascinating!</p> 	 
                    <br/>
                    <h3>Why this website?</h3>
                    <p>This website is part of Armaan-Verse. Armaan-Verse is my universe, an ecosystem, that I am trying to create for two main reasons. One to keep a log of things that I do, I learn, I think, and stuff I want to do. And second to share the knowledge with the other curious learners all around the World. :)</p>
                </div>
            </div>
        </div>
    )
}

export default About;