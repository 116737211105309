import React from 'react'

function Store() {
    return (
        <div className="container">
            <h1 class="display-3 mb-5">My Store</h1>
            This is the store page.
        </div>
    )
}

export default Store;